import React, { useCallback, useMemo } from "react";
import "./App.css";
import {
  Button,
  Image,
  Space,
  Textarea,
  Selector,
  Toast,
  ImageViewer,
  download,
  Page,
} from "@mojo-ui/react";

function App() {
  const [prompt, setPrompt] = React.useState("");
  const [selected, setSelected] = React.useState();
  const [selectedImage, setSelectedImage] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [result, setResult] = React.useState([]);
  const appCode = useMemo(() => "mojo_logo_generation", []);
  const [params, setParams] = React.useState({});
  const [showViewer, setShowViewer] = React.useState(false);

  const options = useMemo(() => {
    return [
      {
        label: (
          <img
            src="https://imagedelivery.net/kizM7KnaQo-C4iKH03WlIA/5d3fae88-abcc-41db-dd2a-336d5b036c00/tiny"
            alt="Nature inspired"
          />
        ),
        value: "1",
        prompt: "nature inspired",
      },
      {
        label: (
          <img
            src="https://imagedelivery.net/kizM7KnaQo-C4iKH03WlIA/925c4f2d-5170-42ec-5d42-7a9e0dc75800/tiny"
            alt="Three tree"
          />
        ),
        value: "2",
        prompt: "three tree",
      },
      {
        label: (
          <img
            src="https://imagedelivery.net/kizM7KnaQo-C4iKH03WlIA/0d7add84-9f3a-4e8e-1aa0-d7a3445cf600/tiny"
            alt="Motorcyle"
          />
        ),
        value: "3",
        prompt: "motorcyle",
      },
      {
        label: (
          <img
            src="https://imagedelivery.net/kizM7KnaQo-C4iKH03WlIA/8bc96834-4f8e-46ac-20a2-7057efe20b00/tiny"
            alt="Gangster owl"
          />
        ),
        value: "4",
        prompt: "a gangster owl, hat, minimal",
      },
    ];
  }, []);

  const handleGenerate = useCallback(async () => {
    try {
      setResult([]);
      setLoading(true);

      const fullPrompt = `${prompt}, Badge, Badge logo, Centered, Digital illustration, Soft color palette, Simple, Vector illustration, Flat illustration, Illustration, Trending on Artstation, Popular on Dribbble`;

      const res = await fetch("https://api.mojo.vn/service/api/art/generate", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": params?.data?.token,
        },
        body: JSON.stringify({
          image_count: 2,
          prompt: fullPrompt,
          ar: "1:1",
          style: "mojo",
          private: true,
        }),
      }).then((res) => res.json());

      if (res?.error) {
        throw new Error(res.error);
      }

      const promises =
        res.result?.map((item) => {
          return new Promise((resolve, reject) => {
            const img = new window.Image();

            img.onload = resolve;
            img.onerror = reject;

            img.src = item;
          });
        }) || [];

      await Promise.all(promises);

      setResult(res.result || []);
    } catch (error) {
      setResult([]);

      Toast.show({
        icon: "fail",
        content: error.message,
      });
    } finally {
      setLoading(false);
    }
  }, [prompt, params]);

  const handlechangeSelector = useCallback(
    (value, extend) => {
      setSelected(value);
      setPrompt(extend.items?.[0]?.prompt);
    },
    [setPrompt, setSelected]
  );

  const content = useMemo(() => {
    return (
      <>
        <Space direction="vertical">
          <Textarea
            placeholder="Nhập nội dung mô tả"
            rows={2}
            value={prompt}
            onChange={setPrompt}
          />
          <Selector
            className="image-selector"
            options={options}
            value={selected}
            onChange={handlechangeSelector}
          />
          <div className="result-container">
            {result?.map((item, index) => (
              <div className="result-container-item" key={index}>
                <Image
                  src={item}
                  onClick={() => {
                    setSelectedImage(item);
                    setShowViewer(true);
                  }}
                />
                <Button
                  label="Tải xuống"
                  size="small"
                  onClick={() => download(item)}
                />
              </div>
            ))}
          </div>
        </Space>
        <ImageViewer
          image={selectedImage}
          visible={showViewer}
          onClose={() => setShowViewer(false)}
        />
      </>
    );
  }, [
    prompt,
    selected,
    result,
    options,
    handlechangeSelector,
    selectedImage,
    showViewer,
  ]);

  const footer = useMemo(() => {
    return (
      <Button
        onClick={handleGenerate}
        loading={loading}
        disabled={loading}
        label="Tạo Logo"
      />
    );
  }, [handleGenerate, loading]);

  return (
    <Page
      config={{
        appCode,
      }}
      content={content}
      footer={footer}
      onLoaded={setParams}
    ></Page>
  );
}

export default App;
